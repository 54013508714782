<template>
  <div class="bg-white p-3 pb-500">
    <UpdateMemberJoinDate :member="changing_member" @refresh="refreshMember" />
    <div class="d-flex align-items-center m-3 border-bottom pb-2">
      <a
        class="fs-18 txt-grey-600 cursor-pointer"
        @click.prevent="backToDetail"
      >
        {{ room ? room.name : "" }}
        <span class="spinner-border" role="status" v-if="!room">
          <span class="sr-only">Loading...</span>
        </span>
      </a>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mx-2"
      >
        <path
          d="M8.29492 17.09L12.8749 12.5L8.29492 7.91L9.70492 6.5L15.7049 12.5L9.70492 18.5L8.29492 17.09Z"
          fill="#667085"
        />
      </svg>
      <a href="javascript:;" class="fs-18 txt-grey-900 mb-0 disabled"
        >Chỉnh sửa</a
      >

      <span class="ml-auto">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer mx-1"
          @click.prevent="backToDetail"
        >
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="black"
          />
        </svg>
      </span>
    </div>
    <form class="container mt-4">
      <div class="mb-3">
        <label for="name" class="required fs-14 fw-500 mb-2 txt-grey-900"
          >Tên Phòng</label
        >
        <input
          type="text"
          class="form-control radius-16 border bg-white fs-16 fw-500 txt-grey-900"
          autocomplete="name"
          v-model="form.name"
        />
      </div>
      <div class="mb-3 row">
        <div class="col-md-6">
          <label for="name" class="required fs-14 fw-500 mb-2 txt-grey-900"
            >Thời gian</label
          >
          <div class="row">
            <div class="col-6">
              <input
                type="date"
                class="form-control radius-16 border bg-white fs-16 fw-500 txt-grey-900"
                v-model="form.date"
              />
            </div>
            <div class="col-6">
              <input
                type="time"
                class="form-control radius-16 border bg-white fs-16 fw-500 txt-grey-900"
                v-model="form.start_time"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label for="name" class="required fs-14 fw-500 mb-2 txt-grey-900"
            >Lặp lại</label
          >
          <select
            v-model="form.repeat_type"
            class="form-select radius-16 border bg-white fs-16 fw-500 txt-grey-900"
          >
            <option :value="0">Không lặp lại</option>
            <option :value="1">Hàng ngày</option>
            <option :value="2">Hàng tuần</option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <label for="name" class="fs-14 fw-500 mb-2 txt-grey-900"
          >Địa điểm</label
        >
        <input
          type="text"
          class="form-control radius-16 border bg-white fs-16 fw-500 txt-grey-900"
          autocomplete="location"
          v-model="form.location"
        />
      </div>
      <div class="text-right pb-5">
        <button
          class="btn bg-pri text-white fs-16 fw-600 px-4"
          type="button"
          @click="updateMdtRoom"
          :disabled="updating"
        >
          {{ !updating ? "Lưu" : "" }}
          <span class="spinner-border" role="status" v-if="updating">
            <span class="sr-only">Loading...</span>
          </span>
        </button>
      </div>
      <div class="mb-3">
        <label for="name" class="fs-14 fw-500 txt-grey-900">
          {{ $t("multidisciplinary_board.lbl_participant") }} ({{
            room_members && room_members.length ? room_members.length : 0
          }})
        </label>
        <div class="d-flex align-items-center my-3">
          <p class="mb-0 txt-grey-900 fs-18 mr-3">
            {{ $t("multidisciplinary_board.lbl_invite_title") }}
          </p>
          <!-- <label for="invitews" class="fs-16 txt-grey-900 cursor-pointer mr-3">
            <input type="checkbox" name id="invitews" v-model="show_org_member" />
            Workspace
          </label>-->
          <div
            class="d-flex align-items-center"
            :title="$t('multidisciplinary_board.lbl_tooltip_invite')"
            data-toggle="helper"
          >
            <label
              for="invitecircle"
              class="fs-16 txt-grey-900 cursor-pointer mr-1"
            >
              <input
                type="checkbox"
                name
                id="invitecircle"
                readonly
                v-model="show_circle_member"
              />
              Circle
            </label>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
                fill="black"
              />
            </svg>
          </div>
        </div>

        <div class="flex">
          <el-select
            class="w-full mr-2"
            v-model="doctorsSelected"
            multiple
            filterable
            allow-create
            default-first-option
            clearable
            :placeholder="
              $t('lbl_enter_the_name_or_email_of_the_doctor_you_want_to_invite')
            "
            remote
            :remote-method="handleFilters"
            :loading="isFiltering"
            @change="handleSelectDoctor"
          >
            <el-option
              class="border border-top-0 border-right-0 border-left-0 border-b border-gray-300 leading-none h-auto"
              v-for="item in doctorsData"
              :key="item.id"
              :label="item.email ? item.email : ''"
              :value="item.email ? item.email : ''"
            >
              <div class="option-grid py-1">
                <div class="option-avatar">
                  <span
                    v-if="item.avatar"
                    class="img-avatar"
                    :style="
                      'background-image: url(' + getImageURL(item.avatar) + ');'
                    "
                  ></span>
                  <span v-else class="img-avatar">
                    {{
                      item.name
                        ? item.name.charAt(0)
                        : item.email
                        ? item.email.charAt(0)
                        : "H"
                    }}
                  </span>
                </div>
                <span class="option-name block font-medium">{{
                  item.name
                }}</span>
                <span class="option-email block">{{ item.email }}</span>
              </div>
            </el-option>
          </el-select>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('lbl_send_an_invitation_to_the_doctor')"
            placement="top"
          >
            <button
              :disabled="adding_member"
              type="button"
              class="py-0 px-3 h-10 bg-pri text-white border-0 rounded-sm"
              @click="addMember"
            >
              <i class="el-icon-s-promotion text-2xl" />
            </button>
          </el-tooltip>
        </div>
      </div>
      <div class="mb-3">
        <div
          class="member-item"
          v-for="mem in room_members"
          :key="mem.id + 'mem'"
        >
          <div class="d-flex align-items-center w-50" :set="(user = mem.user)">
            <span
              v-if="user && user.avatar"
              class="avatar avatar-sm avatar-rounded mr-2"
              :style="'background-image: url(' + getImageURL(user.avatar) + ')'"
            ></span>
            <span
              v-if="user && !user.avatar"
              class="avatar avatar-sm avatar-rounded mr-2"
              >{{ user && user.name ? user.name.charAt(0) : "" }}</span
            >
            <div>
              <p class="fs-14 fw-500 txt-grey-900 mb-0 max-line-1">
                {{ mem.user.name || "" }}
                <span
                  v-if="user.doctor && user.doctor.is_verified !== 4"
                  class="ml-3 text-origin font-bold"
                  >Chưa xác thực</span
                >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-2"
                  v-if="mem.type === 1"
                >
                  <path
                    d="M6.58579 9.41452C7.36684 10.1956 8.63316 10.1956 9.41421 9.41452C10.1953 8.63347 10.1953 7.36714 9.41421 6.58609C8.63316 5.80504 7.36684 5.80504 6.58579 6.58609C5.80474 7.36714 5.80474 8.63347 6.58579 9.41452Z"
                    fill="#20409B"
                  />
                  <ellipse
                    cx="2.66682"
                    cy="7.99983"
                    rx="1.99983"
                    ry="1.99983"
                    fill="#20409B"
                  />
                  <path
                    d="M10 2.66602C10 3.77059 9.10457 4.66602 8 4.66602C6.89543 4.66602 6 3.77059 6 2.66602C6 1.56145 6.89543 0.666016 8 0.666016C9.10457 0.666016 10 1.56145 10 2.66602Z"
                    fill="#20409B"
                  />
                  <path
                    d="M10 13.334C10 14.4386 9.10457 15.334 8 15.334C6.89543 15.334 6 14.4386 6 13.334C6 12.2294 6.89543 11.334 8 11.334C9.10457 11.334 10 12.2294 10 13.334Z"
                    fill="#20409B"
                  />
                  <ellipse
                    cx="13.3328"
                    cy="7.99983"
                    rx="1.99983"
                    ry="1.99983"
                    fill="#20409B"
                  />
                </svg>
              </p>
              <p class="fs-14 txt-grey-900 mb-0 max-line-1">
                {{ user && user.email }}
              </p>
            </div>
          </div>
          <div class="w-50 d-flex justify-content-between align-items-center">
            <div class="dropdown">
              <button
                v-if="$user && $user.id != mem.user_id && isOwner"
                class="change-role-btn border-0 txt-pri fs-14 fw-600 dropdown-toggle"
                type="button"
                :id="'dropdownMenuButtonChangeRole' + mem.id"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ mdtUtils.getRoleName(+mem.role) }}
              </button>
              <button
                v-else
                type="button"
                class="change-role-btn border-0 txt-pri fs-14 fw-600"
              >
                {{ mdtUtils.getRoleName(+mem.role) }}
              </button>

              <div
                class="dropdown-menu w-100"
                :aria-labelledby="'dropdownMenuButtonChangeRole' + mem.id"
              >
                <a class="dropdown-item" @click.prevent="changeRole(1, mem)"
                  >Chủ tọa</a
                >
                <a class="dropdown-item" @click.prevent="changeRole(2, mem)"
                  >Thành viên</a
                >
                <a class="dropdown-item" @click.prevent="changeRole(3, mem)"
                  >Chuyên gia</a
                >
                <a class="dropdown-item" @click.prevent="changeRole(4, mem)"
                  >Thư kí</a
                >
              </div>
            </div>
            <div
              class="member-item-action justify-content-between"
              v-if="doctor_id !== mem.doctor_id"
            >
              <a href="javascript:;" @click="onChangeJoinDate(mem)">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 17.5V19.5H9V17.5H3ZM3 5.5V7.5H13V5.5H3ZM13 21.5V19.5H21V17.5H13V15.5H11V21.5H13ZM7 9.5V11.5H3V13.5H7V15.5H9V9.5H7ZM21 13.5V11.5H11V13.5H21ZM15 9.5H17V7.5H21V5.5H17V3.5H15V9.5Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a v-if="!isOwner" href="javascript:;" @click="removeMember(mem)">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _, { debounce } from 'lodash'
import moment from 'moment'
import UpdateMemberJoinDate from '../../components/HoiChan/UpdateMemberJoinDate.vue'
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'

export default {
  name: 'OrganizationMdtRoomUpdate',
  components: { UpdateMemberJoinDate },
  data () {
    return {
      form: {
        name: '',
        date: '',
        start_time: null,
        end_time: '23:59:59',
        repeat_type: 0,
        location: '',
        password: '',
        clinic_id: ''
      },
      today: moment().format('YYYY-MM-DD'),
      loading: false,
      room: null,
      room_members: [],
      show_circle_member: false,
      show_org_member: true,
      search_member: '',
      mdtUtils,
      doctor_id: null,
      changing_member: null,
      show_list_mem: false,
      doctors: [],
      adding_member: false,
      updating: false,
      circles: [],
      doctorsSelected: [],
      isFiltering: false,
      doctorsData: []
    }
  },
  watch: {
    show_circle_member () {
      this.showCircleMember()
    }
  },
  computed: {
    isOwner () {
      return (
        this.room_members.find((mem) => mem.role === 1 || mem.role === 4)
          ?.user_id === this.$user?.id
      )
    }
  },
  mounted () {
    this.doctor_id = this.$user?.doctor.id
    if (this.$route.params.id) {
      this.prepare()
    } else {
      this.$router.push({ path: '/' })
    }
    window.$(document).ready(function () {
      window.$('[data-toggle="helper"]').tooltip({
        delay: { show: 0, hide: 0 },
        placement: 'top'
      })
    })

    this.doctorsData = this.filteredAndSorted()
  },
  methods: {
    filteredAndSorted () {
      if (!this.doctors || !this.doctors?.length) return []

      // function to compare names
      function compare (a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }
      let search_string = appUtils.removeVietnameseTones(
        this.search_member?.toLowerCase()
      )

      const result = this.doctors
        ?.filter((dr) => {
          let r =
            appUtils
              .removeVietnameseTones(dr?.name?.toLowerCase())
              .includes(search_string) ||
            (dr?.email &&
              appUtils
                .removeVietnameseTones(dr?.email?.toLowerCase())
                .includes(search_string))
          let type =
            this.show_circle_member && this.show_org_member
              ? true
              : this.show_circle_member && !this.show_org_member
                ? !!dr.circle
                : !this.show_circle_member && this.show_org_member
                  ? !dr.circle
                  : false

          return r && type
        })
        .sort(compare)
      return result
    },
    async prepare () {
      try {
        this.loading = true
        await this.getMdtMembers(this.$route.params.id)
        await this.showMdtRoom(this.$route.params.id)
        this.getCircle()
        await this.getOrgConnectiveDoctors()
        // this.getOrgDoctors()
        // let doctor_member = m.find(md => md.doctor_id === this.doctor_id && md.role === 1)
        // if (!doctor_member && c.doctor_id !== this.doctor_id) {
        //   this.$toast.open({
        //     message: 'Phòng không tồn tại hoặc bạn không có quyền thao tác',
        //     type: 'warning'
        //   })
        //   this.backToDetail()
        // }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async getOrgConnectiveDoctors () {
      const self = this
      if (!this.room?.organization_id) return
      let params = {
        org_id: this.room?.organization_id
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getOrgConnectiveDoctors(params)
        .then((r) => {
          self.doctors = r.data
          let dw = r.data?.organization
          let dc = r.data?.circle?.map((dc) => {
            return Object.assign({ circle: 1 }, dc)
          })
          if (dw && dc) {
            self.doctors = _.unionBy(dc, dw, 'id')
          } else if (dw) {
            self.doctors = dw
          } else if (dc) {
            self.doctors = dc
          }
          console.log(self.doctors)
        })
    },
    async getCircle () {
      let self = this
      let params = {
        status: 'accepted',
        limit: 1
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getMyCircles(params)
        .then((r) => {
          self.circles = r.data
        })
    },
    showCircleMember () {
      let self = this
      if (!this.circles.total && this.show_circle_member) {
        this.$swal
          .fire({
            html:
              '<p class="fs-16 fw-500 text-left mt-3">Bạn có thể mời các Bác sĩ và chuyên gia từ các Circle mà mình kết nối.</p>' +
              '<p class="fs-16 fw-500 text-left">Hiện tại bạn chưa có Circle nào. Bạn có muốn tạo Circle ngay không?</p>',
            confirmButtonText: 'Tạo circle'
          })
          .then((result) => {
            if (result.isConfirmed) {
              self.goToCreateCircle()
            } else {
              this.show_circle_member = false
            }
          })
      } else {
        this.handleFilters()
      }
    },
    async getOrgDoctors (isNext) {
      const self = this
      if (!this.$globalOrg) return
      let params = {
        org_id: this.$globalOrg.id,
        page: 1
      }
      if (!isNext) {
        self.doctors = []
      } else {
        params.page = this.doctors.current_page + 1
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getOrgMembers(params)
        .then((r) => {
          if (r && r.data) {
            self.doctors.current_page = r.data.current_page
            if (!isNext) {
              self.doctors = r.data
            } else {
              if (r.data?.count) {
                let old = self.doctors.data
                let newObj = r.data.data
                self.doctors.data = old.concat(newObj)
              }
            }
          }
        })
    },
    async showMdtRoom (id) {
      let self = this
      self.loading = true
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .getPublicMtdRoomDetails(id)
        .then((r) => {
          self.room = r.data
          self.form = {
            name: r.data.name,
            date: r.data.date,
            start_time: r.data.start_time,
            end_time: r.data.end_time,
            repeat_type: r.data.repeat_type,
            location: r.data.location,
            password: '',
            clinic_id: r.data.clinic_id
          }
          return r.data
        })
        .finally(() => {
          self.loading = false
        })
      return r
    },
    async getMdtMembers (id) {
      let self = this
      let params = {
        sort: 'created_at',
        sort_by: 'desc'
      }
      let r = await self.$rf
        .getRequest('DoctorRequest')
        .getMembersOfPublicMdt(id, params)
        .then((r) => {
          self.room_members = r.data
          return r.data
        })
      return r
    },
    async changeRole (role, mem) {
      let self = this
      if (self.room && role && mem) {
        let params = {
          user_id: mem.user_id,
          role: role
        }
        self.$rf
          .getRequest('DoctorRequest')
          .changeRoleOfPublicMdt(self.room.id, params)
          .then(() => {
            self.getMdtMembers(self.room?.id)
            self.$toast.open({
              message: 'Cập nhật thành công',
              type: 'success'
            })
          })
      }
    },
    async removeMember (m) {
      let self = this
      let strcf = 'Bạn có chắc chắn muốn xóa khỏi phòng hội chẩn này?'
      let r = confirm(strcf)
      if (!r) return
      const data = { room_member_ids: [m.id] }
      this.$rf
        .getRequest('DoctorRequest')
        .removeMemberOfPublicMdt(self.room?.id, data)
        .then((r) => {
          self.$toast.open({
            message: 'Xóa thành công',
            type: 'success'
          })
          self.getMdtMembers(self.room?.id)
        })
    },
    async updateMdtRoom () {
      let self = this
      let form = this.form
      if (!form.name) {
        alert('Vui lòng nhập tên phòng hội chẩn')
        return false
      }
      if (!form.date) {
        alert('Vui lòng chọn ngày diễn ra hội chẩn')
        return false
      } else {
        form.date = moment(form.date).format('DD-MM-YYYY')
      }
      if (!form.start_time) {
        alert('Vui lòng thời gian bắt đầu hội chẩn')
        return false
      }
      this.updating = true
      await this.$rf
        .getRequest('DoctorRequest')
        .updatePublicMtdRoom(this.room.id, form)
        .then((r) => {
          self.backToDetail()
        })
        .finally(() => {
          self.updating = false
        })
    },
    async addMember (d) {
      let self = this
      if (!self.room || !self.doctorsSelected.length) return
      this.adding_member = true
      const doctors = await Promise.all(
        self.doctorsSelected?.map(async (doctor) => {
          const data = self
            .filteredAndSorted()
            ?.find((d) => d?.email === doctor)
          if (!data || typeof data === 'undefined') {
            return {
              user_email: doctor
            }
          }
          return {
            user_id: data.id,
            role: 2,
            message: '',
            type: 1
          }
        })
      )
      let mdt_room_id = self.room?.id

      let params = {
        members: doctors
      }
      self.$rf
        .getRequest('DoctorRequest')
        .addMemberOfPublicMdt(mdt_room_id, params)
        .then(() => {
          self.search_member = ''
          self.doctorsSelected = []

          self.$toast.open({
            message: 'Thêm thành công',
            type: 'success'
          })
          self.refreshMember()
        })
        .finally(() => {
          self.adding_member = false
        })
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    selectDr (d) {
      let string =
        'Bạn có chắc chắn muốn thêm ' + d.name + ' vào phòng hội chẩn này'
      let r = confirm(string)
      if (!r) return
      this.addMember(d)
    },
    showModalUpdateJoinDate (show) {
      window.$('#UpdateMemberJoinDate').modal(show ? 'show' : 'hide')
    },
    onChangeJoinDate (member) {
      this.changing_member = member
      this.showModalUpdateJoinDate(true)
    },
    refreshMember () {
      this.showModalUpdateJoinDate()
      if (!this.room) return
      this.getMdtMembers(this.room.id)
    },
    focusOutSearchDoctor () {
      setTimeout(() => {
        this.show_list_mem = false
      }, 150)
    },
    backToDetail () {
      this.$router.push({
        name: 'ListMdt',
        query: { mdtid: this.$route.params.id }
      })
    },
    handleSelectDoctor (value) {
      if (value.length && !this.validateEmail(value.slice(-1)[0])) {
        this.$message({
          type: 'error',
          message: 'Vui lòng nhập đúng định dạng email'
        })
        this.doctorsSelected.pop()
      }
    },
    handleFilters: debounce(function (query) {
      if (query !== '') {
        this.isFiltering = true
        setTimeout(() => {
          this.isFiltering = false
          const drs = this.filteredAndSorted()
          this.doctorsData = drs.filter((item) => {
            return (
              appUtils
                .removeVietnameseTones(item?.name?.toLowerCase())
                .indexOf(appUtils.removeVietnameseTones(query?.toLowerCase())) >
                -1 ||
              item?.email
                ?.toLowerCase()
                .indexOf(appUtils.removeVietnameseTones(query?.toLowerCase())) >
                -1
            )
          })
        }, 200)
      } else {
        this.doctorsData = this.filteredAndSorted()
      }
    }, 500),

    validateEmail (email) {
      return String(email)
        ?.toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
  }
}
</script>

<style lang="scss" scoped>
.member-item {
  height: 75px;
  padding: 16px 16px 16px 3px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e7ec;
}
.member-item-action {
  display: none;
  width: 72px;
}
.member-item:hover .member-item-action {
  display: flex;
}
.change-role-btn {
  background: white;
}
.member-item:hover,
.member-item:hover .change-role-btn {
  background-color: #f2f4f7;
}
.mih-500px {
  min-height: 500px;
}
.img-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #20419b;
}

.list-item-name {
  width: calc(100% - 60px);
}

.list-item-name span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.dropdown-list-m {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 16px;
}

::v-deep {
  .member-item {
    height: 75px;
    padding: 16px 16px 16px 3px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e4e7ec;
  }

  .member-item-action {
    display: none;
    width: 72px;
  }

  .member-item:hover .member-item-action {
    display: flex;
  }

  .change-role-btn {
    background: white;
  }

  .member-item:hover,
  .member-item:hover .change-role-btn {
    background-color: #f2f4f7;
  }

  .mih-500px {
    min-height: 500px;
  }

  .img-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #20419b;
  }

  .list-item-name {
    width: calc(100% - 60px);
  }

  .list-item-name span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .dropdown-list-m {
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 16px;
  }

  .option-grid {
    display: grid;
    grid-template-areas:
      "option-avatar option-name"
      "option-avatar option-email";
    grid-template-columns: 42px 1fr;
    line-height: 1.2;

    .option-avatar {
      grid-area: option-avatar;

      .img-avatar {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .option-name,
    .option-email {
      font-size: 14px;
    }

    .option-name,
    .price {
      font-weight: bold;
    }

    .option-name {
      grid-area: option-name;
    }

    .option-email {
      grid-area: option-email;
    }
  }

  .el-select-dropdown__item {
    height: auto;
    line-height: 1;
  }
}
</style>
